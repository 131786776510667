import AnimatedNumber from 'animated-number-react';
import classnames from 'classnames';
import React, { Component } from 'react';

import apiEtc from '../../../api/etc';
import costComma from '../../../helpers/costComma';
import styles from './MobileIntroStyle.module.scss';

class Section2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyCount: 0,
      contractCount: 0,
      totalClaimCost: 0,
      isView: false,
    };
  }

  componentDidMount() {
    this.setState({ deviceHeight: window.innerHeight });

    this.rencarServiceState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.scrollY !== prevProps.scrollY) {
      this.handleScroll();
    }
  }

  rencarServiceState = async () => {
    const params = {
      menuStore: this.props.menuStore,
      authStore: this.props.authStore,
    };

    try {
      const response = await apiEtc.getCurrentImsformStatus(params);
      const responseData = response.data;

      if (response.status === 200) {
        this.setState({
          companyCount: responseData.company_count,
          contractCount: responseData.contract_count,
          totalClaimCost: responseData.sum_claim_total_cost,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleScroll() {
    if (this.props.scrollY < 1361 && this.props.scrollY > 400) {
      this.setState({ isView: true });
    }
  }

  formatValue = (value) => {
    return costComma(value.toFixed());
  };

  render() {
    return (
      <section className={styles.mobile_section2}>
        <div className={styles.mobile_section2_container}>
          <div className={styles.mobile_section2_area}>
            <div className={styles.mobile_section2_title_area}>
              <div className={classnames([styles.mobile_section2_title, 'wow flipInX'])}>
                이미 많은 업체가
              </div>
              <div className={classnames([styles.mobile_section2_title, 'wow flipInX'])}>
                함께하고 있습니다
              </div>
              <div className={classnames([styles.mobile_section2_subtitle, 'wow fadeInUp'])}>
                IMS form은 렌터카 업체의 업무를
              </div>
              <div className={classnames([styles.mobile_section2_subtitle, 'wow fadeInUp'])}>
                좀 더 편리하고 정확하게 도와드립니다.
              </div>
            </div>

            <div className={classnames([styles.section2_card_area, 'wow fadeIn'])}>
              <div className={styles.section2_card} ref="startCard">
                <div className={styles.section2_card_row}>
                  <label>폼 사용 업체 수</label>
                  <div>
                    <AnimatedNumber
                      value={this.state.isView ? this.state.companyCount : 0}
                      formatValue={this.formatValue}
                    />
                    <span>개 +</span>
                  </div>
                </div>
              </div>
              <div className={styles.section2_card}>
                <div className={styles.section2_card_row}>
                  <label>계약서 작성 건 수</label>
                  <div>
                    <AnimatedNumber
                      value={this.state.isView ? this.state.contractCount : 0}
                      formatValue={this.formatValue}
                    />
                    <span>개 +</span>
                  </div>
                </div>
              </div>
              <div className={styles.section2_card} ref="endCard">
                <div className={styles.section2_card_row}>
                  <label>총 청구 금액</label>
                  <div>
                    <AnimatedNumber
                      value={this.state.isView ? this.state.totalClaimCost : 0}
                      formatValue={this.formatValue}
                    />
                    <span>억 +</span>
                  </div>
                </div>
              </div>
              <div className={styles.section2_data_month}>현재 기준</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Section2;
